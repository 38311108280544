import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NeverError } from '../models/error.model';
import { Manual, ManualCreateParams, ManualStep, ManualStepsCreateParams, Manuals } from '../models/manual.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { ManualGateway } from '../usecases/manual.gateway';
import { ManualUsecase } from '../usecases/manual.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class ManualInteractor extends ManualUsecase {
  get manuals$(): Observable<Manuals> {
    return this._manuals.pipe(
      map(manuals =>
        manuals.values().map(manual => ({
          ...manual,
          manualSteps$: this.listManualSteps(manual.manualId),
        })),
      ),
      map(manuals => new Manuals(manuals)),
    );
  }

  private readonly _manuals = new DistinctSubject<Manuals>(new Manuals());

  constructor(private _webSocketUsecase: WebSocketUsecase, private _manualGateway: ManualGateway) {
    super();
    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'manual'),
        map(({ data }) => data as WebSocketSyncData<Manual>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._manuals.next(this._manuals.value.set(data.payload));
            break;
          case 'delete':
            this._manuals.next(this._manuals.value.delete((data.payload as Manual).manualId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createManual(params: ManualCreateParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._manualGateway.createManual(params).subscribe({
      next: createdManual => {
        this._manuals.next(this._manuals.value.set(createdManual));
        result.next(createdManual.manualId);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteManual(manualId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._manualGateway.deleteManual(manualId).subscribe({
      next: () => this._manuals.next(this._manuals.value.delete(manualId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  createManualSteps(manualId: string, params: ManualStepsCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._manualGateway.createManualSteps(manualId, params).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._manualGateway.listManuals(params), {}).subscribe(manuals => {
      this._manuals.next(new Manuals(manuals));
    });
  }

  private onSignOut(): void {
    this._manuals.next(new Manuals());
  }

  private listManualSteps(manualId: string): Observable<ManualStep[]> {
    return recursiveQuery(params => this._manualGateway.listManualStep(manualId, params), {});
  }
}
