import { Observable } from 'rxjs';

import { Progress } from '../models/progress.model';

export abstract class ProgressUsecase {
  abstract get progress$(): Observable<Progress | null>;

  abstract show(): string;
  abstract update(id: string, progress: Progress): void;
  abstract dismiss(id: string): void;
}
