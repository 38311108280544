import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListVideoQueryParams, Step, StepQueryParams, StepUpdateParams, VoiceQueryParams } from '../models/step.model';
import { ListResponse } from '../models/utility.model';
import { StepGateway } from '../usecases/step.gateway';

@Injectable()
export class StepService extends StepGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listSteps(params: StepQueryParams): Observable<ListResponse<Step>> {
    const { reportId, workId } = params;
    return this._http.get<ListResponse<Step>>(`api/vgs/reports/${reportId}/works/${workId}/steps`, { params });
  }

  updateStep(reportId: string, workId: string, step: number, params: StepUpdateParams): Observable<Step> {
    return this._http.put<Step>(`api/vgs/reports/${reportId}/works/${workId}/steps/${step}`, params);
  }

  listVideos(params: ListVideoQueryParams): Observable<ListResponse<string>> {
    const { reportId, workId, step } = params;
    return this._http.get<ListResponse<string>>(`api/vgs/reports/${reportId}/works/${workId}/steps/${step}/videos`);
  }

  getVoice(params: VoiceQueryParams): Observable<{ url: string }> {
    const { reportId, workId, step } = params;
    return this._http.get<{ url: string }>(`api/vgs/reports/${reportId}/works/${workId}/steps/${step}/voice`);
  }
}
