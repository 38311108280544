import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Thinklet,
  ThinkletCreateParams,
  ThinkletQueryParams,
  ThinkletReactivateParams,
  ThinkletUpdateParams,
} from '../models/thinklet.model';
import { ListResponse } from '../models/utility.model';
import { ThinkletGateway } from '../usecases/thinklet.gateway';

@Injectable()
export class ThinkletService extends ThinkletGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listThinklets(params: ThinkletQueryParams): Observable<ListResponse<Thinklet>> {
    return this._http.get<ListResponse<Thinklet>>('api/vgs/thinklets', { params });
  }

  createThinklets(params: ThinkletCreateParams): Observable<ListResponse<Thinklet> & { registerId: string }> {
    return this._http.post<ListResponse<Thinklet> & { registerId: string }>('api/vgs/thinklets', params);
  }

  updateThinklet(hash: string, params: ThinkletUpdateParams): Observable<Thinklet> {
    return this._http.put<Thinklet>(`api/vgs/thinklets/${hash}/settings`, params);
  }

  deleteThinklet(hash: string): Observable<void> {
    return this._http.delete<void>(`api/vgs/thinklets/${hash}`);
  }

  deactivateThinklet(hash: string): Observable<Thinklet> {
    return this._http.post<Thinklet>(`api/vgs/thinklets/${hash}/deactivate`, null);
  }

  reactivateThinklet(hash: string, params: ThinkletReactivateParams): Observable<Thinklet> {
    return this._http.post<Thinklet>(`api/vgs/thinklets/${hash}/reactivate`, params);
  }
}
