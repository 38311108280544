import { ImmutableMap, Prop, QueryParams, RequiredOnly, isNumber, isObject, isString } from './utility.model';

type OrganizationRequiredProps = {
  name: string;
};

type OrganizationOptionalProps = {
  remarks?: string;
};

type OrganizationProvidedProps = {
  organizationId: string;
  requireMfa: boolean;
  updatedAt: number;
  version: number;
};

export type OrganizationQueryParams = QueryParams;

export type OrganizationCreateParams = OrganizationRequiredProps & OrganizationOptionalProps;

export type OrganizationUpdateParams = Partial<OrganizationRequiredProps> & OrganizationOptionalProps;

export type Organization = OrganizationRequiredProps & OrganizationOptionalProps & OrganizationProvidedProps;

export function isOrganizationCreateParams(value: unknown): value is OrganizationCreateParams {
  const sProps: Prop<RequiredOnly<OrganizationCreateParams>, string>[] = ['name'];
  return isObject(value) && sProps.every(prop => isString(value[prop]));
}

export function isOrganizationUpdateParams(value: unknown): value is OrganizationUpdateParams {
  const sProps: Prop<OrganizationUpdateParams, string>[] = ['name', 'remarks'];
  return isObject(value) && sProps.some(prop => isString(value[prop]));
}

export function isOrganization(value: unknown): value is Organization {
  const sProps: Prop<RequiredOnly<Organization>, string>[] = ['organizationId', 'name'];
  const nProps: Prop<RequiredOnly<Organization>, number>[] = ['updatedAt', 'version'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && nProps.every(prop => isNumber(value[prop]));
}

export class Organizations extends ImmutableMap<string, Organization> {
  constructor(organizations?: Organization[] | Iterable<Organization>) {
    super((organization: Organization) => organization.organizationId, organizations);
  }

  protected clone(organizations?: Organization[] | Iterable<Organization>): this {
    return new Organizations(organizations) as this;
  }
}
