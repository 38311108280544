import { Injectable } from '@angular/core';
import { marker as T } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

const WEEKDAYS = [
  T('core.datepicker.label.monday'),
  T('core.datepicker.label.tuesday'),
  T('core.datepicker.label.wednesday'),
  T('core.datepicker.label.thursday'),
  T('core.datepicker.label.friday'),
  T('core.datepicker.label.saturday'),
  T('core.datepicker.label.sunday'),
];

const MONTHS = [
  T('core.datepicker.label.january'),
  T('core.datepicker.label.february'),
  T('core.datepicker.label.march'),
  T('core.datepicker.label.april'),
  T('core.datepicker.label.may'),
  T('core.datepicker.label.june'),
  T('core.datepicker.label.july'),
  T('core.datepicker.label.august'),
  T('core.datepicker.label.september'),
  T('core.datepicker.label.october'),
  T('core.datepicker.label.november'),
  T('core.datepicker.label.december'),
];

@Injectable()
export class DatepickerI18nService extends NgbDatepickerI18n {
  constructor(private _translate: TranslateService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return !WEEKDAYS[weekday - 1] || this._translate.instant(WEEKDAYS[weekday - 1]);
  }

  getMonthShortName(month: number): string {
    return !MONTHS[month - 1] || this._translate.instant(MONTHS[month - 1]);
  }

  getMonthFullName(month: number): string {
    return !MONTHS[month - 1] || this._translate.instant(MONTHS[month - 1]);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const dayjsDate = dayjs()
      .tz()
      .set({ year: date.year, month: date.month - 1, date: date.day });
    return `${dayjsDate.format('dddd')}, ${dayjsDate.format('LL')}`;
  }
}
