import { Observable } from 'rxjs';

import { WebSocketMessage } from '../models/web-socket.model';

export abstract class WebSocketUsecase {
  abstract get message$(): Observable<WebSocketMessage>;
  abstract get error$(): Observable<CloseEvent>;
  abstract get close$(): Observable<void>;
  abstract get isOpen$(): Observable<boolean>;

  abstract retry(): void;
  abstract sendMessage(message: WebSocketMessage): void;
}
