import { Observable } from 'rxjs';

import { ImageQueryParams, ListVideoQueryParams, Step, StepQueryParams, StepUpdateParams, VoiceQueryParams } from '../models/step.model';
import { ListResponse } from '../models/utility.model';

export abstract class StepGateway {
  abstract listSteps(params: StepQueryParams): Observable<ListResponse<Step>>;
  abstract updateStep(reportId: string, workId: string, step: number, params: StepUpdateParams): Observable<Step>;
  abstract listVideos(params: ListVideoQueryParams): Observable<ListResponse<string>>;
  abstract getVoice(params: VoiceQueryParams): Observable<{ url: string }>;
  abstract getImage(params: ImageQueryParams): Observable<{ url: string }>;
}
