import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions, TFontDictionary, TableLayout } from 'pdfmake/interfaces'; // eslint-disable-line import/no-unresolved
import { AsyncSubject, EMPTY, Observable, concatMap, filter, map, noop, tap, timeout } from 'rxjs';

import { NeverError } from '../models/error.model';
import {
  ImageContent,
  ImageQueryParams,
  ListVideoQueryParams,
  ResultReportCreateParams,
  ResultReportLabels,
  Step,
  StepQueryParams,
  StepUpdateParams,
  Steps,
  VoiceQueryParams,
} from '../models/step.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { StepGateway } from '../usecases/step.gateway';
import { StepUsecase } from '../usecases/step.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const OUTPUT_CONDITION = {
  none: 0,
  output: 1,
} as const;

const PDF_FONT_SIZE = {
  s: 7,
  m: 10,
  l: 11,
  xl: 15,
} as const;

const PDF_PAGE = {
  marginTop: 130,
  marginLeft: 60,
  marginRight: 60,
  marginBottom: 35,
  fontFamily: 'NotoSansJP',
  fontSize: PDF_FONT_SIZE.m,
  lineHeight: 1.1,
} as const;

const PDF_PAGE_HEADER = {
  marginTop: 45,
  marginLeft: 45,
  marginRight: 45,
  marginBottom: 0,
  logo: {
    width: 100 * 1.35,
    height: 27.7 * 1.35,
    offsetLeft: -5,
  },
  title: {
    fontSize: PDF_FONT_SIZE.xl,
    lineHeight: 1,
  },
} as const;

const PDF_PAGE_CONTENT = {
  marginTop: 3,
  marginLeft: -16,
  marginRight: -16,
  cover: {
    firstRow: {
      offsetTop: 150,
      offsetRight: 50,
      lineOffset: 20,
    },
    secondRow: {
      width: '80%',
      offsetTop: 400,
      lineOffset: 50,
    },
  },
  report: {
    table: {
      fontSize: PDF_FONT_SIZE.s,
      firstCol: {
        width: 350,
        offsetLeft: -4,
        offsetRight: 50,
      },
      secondCol: {
        width: 140,
      },
    },
  },
} as const;
const PDF_PAGE_IMAGE_CONTENT = {
  marginTop: 3,
  marginLeft: -16,
  marginRight: -16,
  report: {
    table: {
      fontSize: PDF_FONT_SIZE.s,
      cols: 2,
      rows: 3,
      cell: {
        width: 200,
        height: 200,
      },
    },
    itemsPerPage: 6,
    titleMaxLength: 90,
  },
} as const;

const PDF_PAGE_FOOTER = {
  leftCol: {
    width: '10%',
    marginLeft: 30,
    fontSize: PDF_FONT_SIZE.l,
  },
  centerCol: {
    width: '80%',
    marginTop: 2,
    fontSize: PDF_FONT_SIZE.s,
  },
  rightCol: {
    width: '10%',
    marginRight: 30,
    fontSize: PDF_FONT_SIZE.l,
  },
} as const;

const PDF_TABLE_BORDER_BOTTOM_ONLY_LAYOUT: TableLayout = {
  hLineWidth: i => (i === 0 ? 0 : 1),
  vLineWidth: () => 0,
  hLineColor: () => 'gray',
  vLineColor: () => '',
};
const PDF_TABLE_PHOTO_LAYOUT: TableLayout = {
  hLineWidth: () => 1,
  vLineWidth: index => (index == 0 || index == PDF_PAGE_IMAGE_CONTENT.report.table.cols ? 0 : 1),
  hLineColor: index => (index == 2 || index == 4 ? 'black' : 'white'),
  vLineColor: () => '',
};

@Injectable()
export class StepInteractor extends StepUsecase {
  get steps$(): Observable<Steps> {
    return this._steps;
  }

  private readonly _steps = new DistinctSubject<Steps>(new Steps());

  constructor(private _webSocketUsecase: WebSocketUsecase, private _stepGateway: StepGateway) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? noop : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'achievement'),
        map(({ data }) => data as WebSocketSyncData<Step>),
      )
      .subscribe(data => {
        const step = data.payload as Step;
        switch (data.reason) {
          case 'create':
          case 'update':
            if (this._steps.value.values().some(({ workId }) => workId === step.workId)) {
              this._steps.next(this._steps.value.set(step));
            }
            break;
          case 'delete': {
            this._steps.next(this._steps.value.delete(`${step.workId}_${step.step}`));
            break;
          }
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  listSteps(params: StepQueryParams): Observable<never> {
    this._steps.next(new Steps());
    return recursiveQuery(paramsA => this._stepGateway.listSteps(paramsA), params).pipe(
      tap(steps => this._steps.next(new Steps(steps))),
      concatMap(() => EMPTY),
    );
  }

  updateStep(reportId: string, workId: string, step: number, params: StepUpdateParams): Observable<void> {
    const result = new AsyncSubject<void>();
    this._stepGateway.updateStep(reportId, workId, step, params).subscribe({
      next: updatedStep => {
        this._steps.next(this._steps.value.set(updatedStep));
        result.next();
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listVideos(params: ListVideoQueryParams): Observable<string[]> {
    const result = new AsyncSubject<string[]>();
    this._stepGateway.listVideos(params).subscribe({
      next: ({ items }) => result.next(items),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getVoice(params: VoiceQueryParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._stepGateway.getVoice(params).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getImage(params: ImageQueryParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._stepGateway.getImage(params).subscribe({
      next: ({ url }) => result.next(url),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  clearSteps(): void {
    this._steps.next(new Steps());
  }

  exportReport(params: ResultReportCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this.generateReport(params)
      .pipe(timeout(1000 * 60 * 3))
      .subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result;
  }

  private onSignOut(): void {
    this._steps.next(new Steps());
  }

  private generateReport(params: ResultReportCreateParams): Observable<never> {
    const fileName = `vgs-report-${params.workName}-${params.manualName}`;

    return new Observable(subscriber => {
      const { imageContents, pageCnt } = this.generateImageContents(params.imageContents);
      const docDefinition: TDocumentDefinitions = {
        header: (currentPage, pageSize) => this.generateHeaderContent(currentPage, pageSize, params, pageCnt),
        content: [
          this.generateCoverContent(params.labels),
          ...this.generateReportContents(params.steps),
          ...imageContents,
          this.generateSummaryContent(params.summary),
        ],
        footer: currentPage => this.generateFooterContent(currentPage, fileName),
        defaultStyle: {
          font: PDF_PAGE.fontFamily,
          fontSize: PDF_PAGE.fontSize,
          lineHeight: PDF_PAGE.lineHeight,
        },
        images: {
          logo: `${location.protocol}//${location.host}/assets/images/logo.png`,
        },
        pageMargins: [PDF_PAGE.marginLeft, PDF_PAGE.marginTop, PDF_PAGE.marginRight, PDF_PAGE.marginBottom],
        pageSize: 'A4',
      };
      const fonts: TFontDictionary = {
        [PDF_PAGE.fontFamily]: {
          normal: `${location.protocol}//${location.host}/assets/fonts/NotoSansJP-Regular.ttf`,
          bold: `${location.protocol}//${location.host}/assets/fonts/NotoSansJP-Bold.ttf`,
        },
      };
      pdfMake.createPdf(docDefinition, undefined, fonts).download(`${fileName}.pdf`, () => subscriber.complete());
    });
  }

  private generateHeaderContent(
    currentPage: number,
    pageSize: number,
    params: ResultReportCreateParams,
    imageContentPageCount: number,
  ): Content {
    const isCoverPage = currentPage === 1;
    const isImagePage = pageSize - imageContentPageCount - 1 < currentPage;
    const isSummaryPage = currentPage === pageSize;
    const isFirstReportPage = currentPage === 2;

    let title = '';
    if (isCoverPage) {
      title = params.workName;
    } else if (isSummaryPage) {
      title = params.labels.summary;
    } else if (isImagePage) {
      title = params.labels.photo;
    } else {
      title = isFirstReportPage ? params.manualName : `${params.manualName} - ${params.labels.continue}`;
    }

    return {
      stack: [
        {
          image: 'logo',
          width: PDF_PAGE_HEADER.logo.width,
          height: PDF_PAGE_HEADER.logo.height,
          marginLeft: PDF_PAGE_HEADER.logo.offsetLeft,
        },
        {
          text: title,
          bold: true,
          fontSize: PDF_PAGE_HEADER.title.fontSize,
          lineHeight: PDF_PAGE_HEADER.title.lineHeight,
        },
      ],
      margin: [PDF_PAGE_HEADER.marginLeft, PDF_PAGE_HEADER.marginTop, PDF_PAGE_HEADER.marginRight, PDF_PAGE_HEADER.marginBottom],
    };
  }

  private generateCoverContent({ attendanceDateAndTime, engineer, customer }: ResultReportLabels): Content {
    return {
      pageBreak: 'after',
      marginTop: PDF_PAGE_CONTENT.marginTop,
      marginLeft: PDF_PAGE_CONTENT.marginLeft,
      marginRight: PDF_PAGE_CONTENT.marginRight,
      bold: true,
      color: 'gray',
      stack: [
        {
          marginTop: PDF_PAGE_CONTENT.cover.firstRow.offsetTop,
          layout: PDF_TABLE_BORDER_BOTTOM_ONLY_LAYOUT,
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: attendanceDateAndTime,
                  alignment: 'right',
                  marginRight: PDF_PAGE_CONTENT.cover.firstRow.offsetRight,
                  marginBottom: PDF_PAGE_CONTENT.cover.firstRow.lineOffset,
                },
              ],
            ],
          },
        },
        {
          marginTop: PDF_PAGE_CONTENT.cover.secondRow.offsetTop,
          layout: 'noBorders',
          table: {
            widths: ['*', '*'],
            body: [
              [engineer, customer].map(label => ({
                layout: PDF_TABLE_BORDER_BOTTOM_ONLY_LAYOUT,
                table: {
                  widths: PDF_PAGE_CONTENT.cover.secondRow.width,
                  body: [
                    [
                      {
                        text: label,
                        marginBottom: PDF_PAGE_CONTENT.cover.secondRow.lineOffset,
                      },
                    ],
                  ],
                },
              })),
            ],
          },
        },
      ],
    };
  }

  private generateReportContents(steps: Step[]): Content[] {
    const segments = steps.reduce((acc, cur) => {
      const lastSegment = acc.at(-1);
      const lastStep = lastSegment?.steps.at(-1);
      if (lastSegment && cur.segment === lastStep?.segment) {
        lastSegment.steps.push(cur);
      } else {
        acc.push({ steps: [cur] });
      }
      return acc;
    }, [] as { steps: Step[] }[]);

    const filteredSegments = segments
      .map(segment => {
        segment.steps = segment.steps.filter(step => {
          return step.outputCondition === OUTPUT_CONDITION.output && (step.skip !== 'condition' || step.editor);
        });
        return segment;
      })
      .filter(segment => segment.steps.length);

    return filteredSegments.map(segment => {
      return {
        marginTop: PDF_PAGE_CONTENT.marginTop,
        marginLeft: PDF_PAGE_CONTENT.marginLeft,
        marginRight: PDF_PAGE_CONTENT.marginRight,
        stack: [
          {
            text: segment.steps[0].segment,
            bold: true,
          },
          {
            layout: 'noBorders',
            table: {
              body: segment.steps.map(({ no, result, title }) => {
                return [
                  {
                    layout: PDF_TABLE_BORDER_BOTTOM_ONLY_LAYOUT,
                    fontSize: PDF_PAGE_CONTENT.report.table.fontSize,
                    dontBreakRows: true,
                    unbreakable: true,
                    table: {
                      widths: [PDF_PAGE_CONTENT.report.table.firstCol.width, PDF_PAGE_CONTENT.report.table.secondCol.width],
                      body: [
                        [
                          {
                            text: `No.${no} ${title}`,
                            alignment: 'left',
                            marginLeft: PDF_PAGE_CONTENT.report.table.firstCol.offsetLeft,
                            marginRight: PDF_PAGE_CONTENT.report.table.firstCol.offsetRight,
                          },
                          {
                            text: `-   ${result || ''}`,
                          },
                        ],
                      ],
                    },
                  },
                ];
              }),
            },
          },
        ],
        pageBreak: 'after',
      };
    });
  }

  private generateImageContents(images: ImageContent[]): { imageContents: Content[]; pageCnt: number } {
    let pageCnt = 1;
    const imageContents: Content[] = [];
    const { table, itemsPerPage, titleMaxLength } = PDF_PAGE_IMAGE_CONTENT.report;

    for (let i = 0; i < images.length; i += itemsPerPage) {
      const pageImages = images.slice(i, i + itemsPerPage);
      const body = [];

      for (let r = 0; r < table.rows; r++) {
        const titleRow: Content[] = [];
        for (let c = 0; c < table.cols; c++) {
          const img = pageImages[r * table.cols + c];
          if (img) {
            const { no, count, title } = img;
            titleRow.push({
              text: `No.${no}-${count} ${title.slice(0, titleMaxLength)}${title.length > titleMaxLength ? '…' : ''}`,
              fontSize: table.fontSize,
              alignment: 'left',
            });
          } else {
            titleRow.push({ text: '' });
          }
        }
        body.push(titleRow);

        const imageRow: Content[] = [];
        for (let c = 0; c < table.cols; c++) {
          const img = pageImages[r * table.cols + c];
          if (img) {
            const { dataUrl, width, height } = img;
            const scaleX = (table.cell.width - 25) / width;
            const scaleY = (table.cell.height - 25) / height;
            const scale = Math.min(scaleX, scaleY, 1);
            imageRow.push({
              image: dataUrl,
              width: width * scale,
              height: height * scale,
              alignment: 'center',
              margin: [0, (table.cell.height - 25 - height * scale) / 2, 0, (table.cell.height - 15 - height * scale) / 2],
            });
          } else {
            imageRow.push({
              text: '',
              margin: [table.cell.width / 2, (table.cell.height - 10) / 2, table.cell.width / 2, (table.cell.height - 10) / 2],
            });
          }
        }
        body.push(imageRow);
      }

      imageContents.push({
        marginLeft: PDF_PAGE_IMAGE_CONTENT.marginLeft,
        marginRight: PDF_PAGE_IMAGE_CONTENT.marginRight,
        stack: [
          {
            table: {
              widths: ['50%', '50%'],
              body: body,
            },
            layout: PDF_TABLE_PHOTO_LAYOUT,
          },
        ],
      });

      if (i + itemsPerPage < images.length) {
        pageCnt++;
        imageContents.push({ text: '', pageBreak: 'after' });
      }
    }
    return { imageContents, pageCnt };
  }

  private generateSummaryContent(summary: string): Content {
    return {
      pageBreak: 'before',
      marginTop: PDF_PAGE_CONTENT.marginTop,
      marginLeft: PDF_PAGE_CONTENT.marginLeft,
      marginRight: PDF_PAGE_CONTENT.marginRight,
      stack: [
        {
          text: summary,
        },
      ],
    };
  }

  private generateFooterContent(currentPage: number, fileName: string): Content {
    const isEvenPage = currentPage % 2 === 0;
    const isCoverPage = currentPage === 1;

    return {
      columns: [
        {
          text: isEvenPage ? `${currentPage - 1}` : '',
          alignment: 'left',
          width: PDF_PAGE_FOOTER.leftCol.width,
          fontSize: PDF_PAGE_FOOTER.leftCol.fontSize,
          marginLeft: PDF_PAGE_FOOTER.leftCol.marginLeft,
        },
        {
          text: fileName,
          alignment: 'center',
          width: PDF_PAGE_FOOTER.centerCol.width,
          fontSize: PDF_PAGE_FOOTER.centerCol.fontSize,
          color: 'gray',
          marginTop: PDF_PAGE_FOOTER.centerCol.marginTop,
        },
        {
          text: !isEvenPage && !isCoverPage ? `${currentPage - 1}` : '',
          alignment: 'right',
          width: PDF_PAGE_FOOTER.rightCol.width,
          fontSize: PDF_PAGE_FOOTER.rightCol.fontSize,
          marginRight: PDF_PAGE_FOOTER.rightCol.marginRight,
        },
      ],
    };
  }
}
