import { Observable } from 'rxjs';

import { ThinkletCreateParams, ThinkletReactivateParams, ThinkletUpdateParams, Thinklets } from '../models/thinklet.model';

export abstract class ThinkletUsecase {
  abstract get thinklets$(): Observable<Thinklets>;

  abstract createThinklets(params: ThinkletCreateParams): Observable<string>;
  abstract updateThinklet(hash: string, params: ThinkletUpdateParams): Observable<never>;
  abstract deleteThinklet(hash: string): Observable<never>;
  abstract deactivateThinklet(hash: string): Observable<never>;
  abstract reactivateThinklet(hash: string, params: ThinkletReactivateParams): Observable<never>;
}
