import { ImmutableMap, Prop, QueryParams, RequiredOnly, isNumber, isObject, isString } from './utility.model';
import { Work } from './work.model';

export const YES_NO_VALUE_EN = ['yes', 'no'] as const;
export const YES_NO_VALUE_DE = ['ja', 'nein'] as const;
export const YES_NO_VALUE_JA = ['OK', 'NG'] as const;
export const YES_NO_VALUE = [...YES_NO_VALUE_EN, ...YES_NO_VALUE_DE, ...YES_NO_VALUE_JA] as const;
export type YesNoValue = (typeof YES_NO_VALUE)[number];

type ReportRequiredProps = {
  organizationId: string;
  name: string;
  site: string;
  scheduledAt: number;
};

type ReportOptionalProps = {
  remarks?: string;
};

type ReportProvidedProps = {
  reportId: string;
  works?: Work[];
  createdAt: number;
  updatedAt: number;
  version: number;
  author: string;
  assignedUsers: string[];
};

export type ReportQueryParams = QueryParams & { organizationId?: string; scheduledFrom: string; scheduledTo: string };

export type ReportCreateParams = ReportRequiredProps & ReportOptionalProps & { organizationId: string };

export type ReportUpdateParams = Partial<Omit<ReportRequiredProps, 'organizationId'>> & ReportOptionalProps;

export type Report = ReportRequiredProps & ReportOptionalProps & ReportProvidedProps;

export function isReportCreateParams(value: unknown): value is ReportCreateParams {
  const sProps: Prop<RequiredOnly<ReportCreateParams>, string>[] = ['organizationId', 'name', 'site'];
  const nProps: Prop<RequiredOnly<ReportCreateParams>, number>[] = ['scheduledAt'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && nProps.every(prop => isNumber(value[prop]));
}

export function isReportUpdateParams(value: unknown): value is ReportUpdateParams {
  const sProps: Prop<ReportUpdateParams, string>[] = ['name', 'site', 'remarks'];
  const nProps: Prop<ReportUpdateParams, number>[] = ['scheduledAt'];
  return isObject(value) && (sProps.some(prop => isString(value[prop])) || nProps.some(prop => isNumber(value[prop])));
}

export class Reports extends ImmutableMap<string, Report> {
  constructor(reports?: Report[] | Iterable<Report>) {
    super((report: Report) => report.reportId, reports);
  }

  protected clone(reports?: Report[] | Iterable<Report>): this {
    return new Reports(reports) as this;
  }
}
