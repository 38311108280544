import { Observable } from 'rxjs';

import { ManualCreateParams, ManualStep, ManualStepsCreateParams, Manuals } from '../models/manual.model';
import { ListResponse } from '../models/utility.model';

export abstract class ManualUsecase {
  abstract get manuals$(): Observable<Manuals>;

  abstract createManual(params: ManualCreateParams): Observable<string>;
  abstract deleteManual(organizationId: string): Observable<never>;

  abstract createManualSteps(manualId: string, params: ManualStepsCreateParams): Observable<ListResponse<ManualStep>>;
}
