import { InjectionToken } from '@angular/core';

export type Environment = {
  production: boolean;
  region: string;
  identityPoolId: string;
  userPoolId: string;
  userPoolWebClientId: string;
  apiUrl: string;
  wssUrl: string;
};

export const ENVIRONMENT = new InjectionToken<Environment>('Environment');
