import { Observable } from 'rxjs';

import { ReportCreateParams, ReportUpdateParams, Reports } from '../models/report.model';
import { UserTimezone } from '../models/user.model';
import { Period } from '../models/utility.model';
import { WorkCreateParams, WorkUpdateParams } from '../models/work.model';

export abstract class ReportUsecase {
  abstract get reports$(): Observable<Reports>;
  abstract get period$(): Observable<Period>;
  abstract get workUpdateNotice$(): Observable<void>;

  abstract changeTimezone(timezone: UserTimezone): void;
  abstract changePeriod(period: Period): void;

  abstract createReport(params: ReportCreateParams): Observable<never>;
  abstract updateReport(reportId: string, params: ReportUpdateParams): Observable<never>;
  abstract deleteReport(reportId: string): Observable<never>;

  abstract createWork(reportId: string, params: WorkCreateParams): Observable<string>;
  abstract updateWork(reportId: string, workId: string, params: WorkUpdateParams): Observable<never>;
  abstract updateWorkAssign(reportId: string, workId: string): Observable<never>;
  abstract deleteWork(reportId: string, workId: string): Observable<never>;
}
