import { Observable } from 'rxjs';

import { ImmutableMap, QueryParams } from './utility.model';

export type ManualType = 'question';
export type ManualResultType = 'yesno' | 'select' | 'digit' | 'naturalNumber';

type ManualRequiredProps = {
  organizationId: string;
  name: string;
};

type ManualOptionalProps = {
  remarks?: string;
};

type ManualProvidedProps = {
  manualId: string;
  registrant: string;
  registrantName: string;
  createdAt: number;
  updatedAt: number;
  version: number;
};

export type ManualQueryParams = QueryParams & { organizationId?: string };

export type ManualStepQueryParams = QueryParams;

export type ManualCreateParams = ManualRequiredProps & ManualOptionalProps;

export type Manual = ManualRequiredProps &
  ManualOptionalProps &
  ManualProvidedProps & {
    manualSteps$: Observable<ManualStep[]>;
  };

type ManualStepRequiredProps = {
  no: number;
  type: ManualType;
  segment: string;
  title: string;
  body: string;
  resultType: ManualResultType;
  skipCondition: string;
  addGuideCondition: string;
  skipConditionAst: string;
  addGuideConditionAst: string;
  addGuideBody: string;
  outputCondition: number;
  photoOutputCondition: number;
};

type ManualStepProvidedProps = {
  manualId: string;
  step: number;
  organizationId: string;
  updatedAt: number;
  version: number;
};

export type ManualStepsCreateParams = {
  items: ManualStepRequiredProps[];
};

export type ManualStep = ManualStepRequiredProps & ManualStepProvidedProps;

export class Manuals extends ImmutableMap<string, Manual> {
  constructor(manuals?: Manual[] | Iterable<Manual>) {
    super((manual: Manual) => manual.manualId, manuals);
  }

  protected clone(manuals?: Manual[] | Iterable<Manual>): this {
    return new Manuals(manuals) as this;
  }
}
