import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Organization, OrganizationCreateParams, OrganizationQueryParams, OrganizationUpdateParams } from '../models/organization.model';
import { ListResponse } from '../models/utility.model';
import { OrganizationGateway } from '../usecases/organization.gateway';

@Injectable()
export class OrganizationService extends OrganizationGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listOrganizations(params: OrganizationQueryParams): Observable<ListResponse<Organization>> {
    return this._http.get<ListResponse<Organization>>('api/vgs/organizations', { params });
  }

  createOrganization(params: OrganizationCreateParams): Observable<Organization> {
    return this._http.post<Organization>('api/vgs/organizations', params);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    return this._http.get<Organization>(`api/vgs/organizations/${organizationId}`);
  }

  updateOrganization(organizationId: string, params: OrganizationUpdateParams): Observable<Organization> {
    return this._http.put<Organization>(`api/vgs/organizations/${organizationId}`, params);
  }

  deleteOrganization(organizationId: string): Observable<void> {
    return this._http.delete<void>(`api/vgs/organizations/${organizationId}`);
  }

  updateOrganizationMfa(organizationId: string, requireMfa: boolean): Observable<Organization> {
    return this._http.put<Organization>(`api/vgs/organizations/${organizationId}/mfa`, { requireMfa });
  }
}
