import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule, NgbModalModule, NgbProgressbarModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ENVIRONMENT } from './core/models/environment.model';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  providers: [{ provide: ENVIRONMENT, useValue: environment }],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbToastModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ja',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    AppRoutingModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
