import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Device, Devices } from '../models/device.model';
import { NeverError } from '../models/error.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { DeviceGateway } from '../usecases/device.gateway';
import { DeviceUsecase } from '../usecases/device.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class DeviceInteractor extends DeviceUsecase {
  get devices$(): Observable<Devices> {
    return this._devices;
  }

  private readonly _devices = new DistinctSubject<Devices>(new Devices());

  constructor(private _webSocketUsecase: WebSocketUsecase, private _deviceGateway: DeviceGateway) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'device'),
        map(({ data }) => data as WebSocketSyncData<Device>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._devices.next(this._devices.value.set(data.payload));
            break;
          case 'delete':
            this._devices.next(this._devices.value.delete(data.payload.deviceId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  private onSignIn(): void {
    recursiveQuery(params => this._deviceGateway.listDevices(params), {}).subscribe(devices => {
      this._devices.next(new Devices(devices));
    });
  }

  private onSignOut(): void {
    this._devices.next(new Devices());
  }
}
