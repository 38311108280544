import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable, filter, finalize, map, partition, repeat, takeUntil } from 'rxjs';

import { NeverError } from '../models/error.model';
import { Report, ReportCreateParams, ReportQueryParams, ReportUpdateParams, Reports } from '../models/report.model';
import { UserTimezone } from '../models/user.model';
import { DistinctSubject, Period, PeriodSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { Work, WorkCreateParams, WorkUpdateParams } from '../models/work.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { ReportGateway } from '../usecases/report.gateway';
import { ReportUsecase } from '../usecases/report.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';
import { WorkGateway } from '../usecases/work.gateway';

@Injectable()
export class ReportInteractor extends ReportUsecase {
  get reports$(): Observable<Reports> {
    return this._reports;
  }
  get period$(): Observable<Period> {
    return this._period;
  }
  get workUpdateNotice$(): Observable<void> {
    return this._workUpdateNotice;
  }

  private readonly _reports = new DistinctSubject<Reports>(new Reports());
  private readonly _period = new PeriodSubject({ from: -1, to: -1 });
  private readonly _workUpdateNotice = new BehaviorSubject<void>(void 0);

  constructor(
    private _authUsecase: AuthUsecase,
    private _webSocketUsecase: WebSocketUsecase,
    private _reportGateway: ReportGateway,
    private _workGateway: WorkGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this._period.now();
    });

    const [open$, close$] = partition(this._webSocketUsecase.isOpen$, isOpen => isOpen);
    this.period$
      .pipe(
        filter(({ from, to }) => from >= 0 && to >= 0),
        takeUntil(close$),
        finalize(() => this._reports.next(new Reports())),
        repeat({ delay: () => open$ }),
      )
      .subscribe(({ from, to }) => this.listReports(from, to));

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'report'),
        map(({ data }) => data as WebSocketSyncData<Report>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._reports.next(this._reports.value.set(data.payload as Report));
            break;
          case 'delete': {
            this._reports.next(this._reports.value.delete((data.payload as Report).reportId));
            break;
          }
          default:
            throw new NeverError(data.reason);
        }
      });
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'work'),
        map(({ data }) => data as WebSocketSyncData<Work>),
      )
      .subscribe(data => {
        const work = data.payload as Work;
        switch (data.reason) {
          case 'create':
            this.onCreateWork(work);
            break;
          case 'update':
            this.onUpdateWork(work);
            break;
          case 'delete':
            this.onDeleteWork(work.reportId, work.workId);
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  changeTimezone(timezone: UserTimezone): void {
    this._period.tz(timezone);
  }

  changePeriod(period: Period): void {
    this._period.next(period);
  }

  createReport(params: ReportCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._reportGateway.createReport(params).subscribe({
      next: createdReport => this._reports.next(this._reports.value.set(createdReport)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateReport(reportId: string, params: ReportUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._reportGateway.updateReport(reportId, params).subscribe({
      next: updatedReport => this._reports.next(this._reports.value.set(updatedReport)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteReport(reportId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._reportGateway.deleteReport(reportId).subscribe({
      next: () => this._reports.next(this._reports.value.delete(reportId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  createWork(reportId: string, params: WorkCreateParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._workGateway.createWork(reportId, params).subscribe({
      next: createdWork => {
        result.next(createdWork.workId);
        this.onCreateWork(createdWork);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateWork(reportId: string, workId: string, params: WorkUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._workGateway.updateWork(reportId, workId, params).subscribe({
      next: updatedWork => this.onUpdateWork(updatedWork),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateWorkAssign(reportId: string, workId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._workGateway.updateWorkAssign(reportId, workId).subscribe({
      next: updatedWork => this.onUpdateWork(updatedWork),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteWork(reportId: string, workId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._workGateway.deleteWork(reportId, workId).subscribe({
      next: () => this.onDeleteWork(reportId, workId),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onCreateWork(createdWork: Work): void {
    const report = this._reports.value.get(createdWork.reportId);
    if (report && !report.works?.find(({ workId }) => workId === createdWork.workId)) {
      report.works = [...(report.works || []), createdWork];
      this._reports.next(this._reports.value.set(report));
      this._workUpdateNotice.next();
    }
  }

  private onUpdateWork(updatedWork: Work): void {
    const report = this._reports.value.get(updatedWork.reportId);
    if (report && report.works?.find(({ workId, version }) => workId === updatedWork.workId && version < updatedWork.version)) {
      report.works = report.works?.reduce((acc, cur) => {
        const work = cur.workId === updatedWork.workId ? updatedWork : cur;
        return [...acc, work];
      }, [] as Work[]);
      this._reports.next(this._reports.value.set(report));
      this._workUpdateNotice.next();
    }
  }

  private onDeleteWork(reportId: string, workId: string): void {
    const report = this._reports.value.get(reportId);
    if (report) {
      report.works = report.works?.reduce((acc, cur) => {
        return cur.workId === workId ? acc : [...acc, cur];
      }, [] as Work[]);
      this._reports.next(this._reports.value.set(report));
      this._workUpdateNotice.next();
    }
  }

  private listReports(from: number, to: number): void {
    const queryParams = {
      scheduledFrom: from.toString(),
      scheduledTo: to.toString(),
    } as ReportQueryParams;
    recursiveQuery(params => this._reportGateway.listReports(params), queryParams).subscribe(reports => {
      this._reports.next(new Reports(reports));
    });
  }

  private onSignIn(): void {
    const { from, to } = this._period.value;
    this.listReports(from, to);
  }

  private onSignOut(): void {
    this._reports.next(new Reports());
  }
}
