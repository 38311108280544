import { ImmutableMap, Prop, QueryParams, isBoolean, isNumber, isObject, isString } from './utility.model';

export type ThinkletSortItems = 'statusAsc' | 'statusDesc';

export type ThinkletImportErrorReason = 'imei' | 'organizationId';

export type ThinkletImportError = {
  imei: string;
  reason: ThinkletImportErrorReason;
};

export const THINKLET_QUALITIES = ['low', 'mid', 'high'] as const;
export type ThinkletQuality = (typeof THINKLET_QUALITIES)[number];

export const THINKLET_GAINS = ['auto', 'low', 'mid', 'high'] as const;
export type ThinkletGain = (typeof THINKLET_GAINS)[number];

export const THINKLET_ECHOS = ['off', 'low', 'mid', 'high'] as const;
export type ThinkletEcho = (typeof THINKLET_ECHOS)[number];

export const THINKLET_NOISES = ['off', 'low', 'mid', 'high'] as const;
export type ThinkletNoise = (typeof THINKLET_NOISES)[number];

export const THINKLET_DIAGONALS = ['off', 'low', 'mid', 'high'] as const;
export type ThinkletDiagonal = (typeof THINKLET_DIAGONALS)[number];

export const THINKLET_STATUSES = ['available', 'unavailable'] as const;
export type ThinkletStatus = (typeof THINKLET_STATUSES)[number];

type ThinkletRequiredProps = {
  name: string;
  imei: string;
  organizationId: string;
  quality: ThinkletQuality;
  gain: ThinkletGain;
  echo: ThinkletEcho;
  thresholdDbfs: number;
  noise: ThinkletNoise;
  diagonal: ThinkletDiagonal;
  windMode: boolean;
  activeTime: number;
  inactiveTime: number;
  headPadding: number;
  tailPadding: number;
};

type ThinkletOptionalProps = {
  owner?: string;
};

type ThinkletProvidedProps = {
  hash: string;
  deviceId: string;
  status: ThinkletStatus;
  registerId: string;
  registeredAt: number;
  workId: string;
  reportId: string;
  assignedAt: number;
  updatedAt: number;
  version: number;
};

export type ThinkletQueryParams = QueryParams;

export type ThinkletCreateParams = {
  items: ThinkletRequiredProps[];
};

export type ThinkletUpdateParams = Partial<
  Pick<
    ThinkletRequiredProps,
    | 'name'
    | 'quality'
    | 'gain'
    | 'echo'
    | 'thresholdDbfs'
    | 'noise'
    | 'diagonal'
    | 'windMode'
    | 'activeTime'
    | 'inactiveTime'
    | 'headPadding'
    | 'tailPadding'
  >
> &
  ThinkletOptionalProps;

export type ThinkletReactivateParams = Omit<ThinkletRequiredProps, 'imei'>;

export type Thinklet = ThinkletRequiredProps & ThinkletOptionalProps & ThinkletProvidedProps;

export function isThinkletUpdateParams(value: unknown): value is ThinkletUpdateParams {
  const sProps: Prop<ThinkletUpdateParams, string>[] = ['name', 'owner', 'quality', 'gain', 'echo', 'noise', 'diagonal'];
  const nProps: Prop<ThinkletUpdateParams, number>[] = ['thresholdDbfs', 'activeTime', 'inactiveTime', 'headPadding', 'tailPadding'];
  const bProps: Prop<ThinkletUpdateParams, boolean>[] = ['windMode'];
  return (
    isObject(value) &&
    (sProps.some(prop => isString(value[prop])) ||
      nProps.some(prop => isNumber(value[prop])) ||
      bProps.some(prop => isBoolean(value[prop])))
  );
}

export class Thinklets extends ImmutableMap<string, Thinklet> {
  constructor(thinklets?: Thinklet[] | Iterable<Thinklet>) {
    super(thinklet => thinklet.hash, thinklets);
  }

  protected clone(thinklets?: Thinklet[] | Iterable<Thinklet>): this {
    return new Thinklets(thinklets) as this;
  }
}
