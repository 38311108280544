import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { AuthInteractor } from './interactors/auth.interactor';
import { DeviceInteractor } from './interactors/device.interactor';
import { ManualInteractor } from './interactors/manual.interactor';
import { MgmtDeptInteractor } from './interactors/mgmt-dept.interactor';
import { OrganizationInteractor } from './interactors/organization.interactor';
import { ProgressInteractor } from './interactors/progress.interactor';
import { ReportInteractor } from './interactors/report.interactor';
import { StepInteractor } from './interactors/step.interactor';
import { ThinkletInteractor } from './interactors/thinklet.interactor';
import { ToastInteractor } from './interactors/toast.interactor';
import { UserInteractor } from './interactors/user.interactor';
import { WebSocketInteractor } from './interactors/web-socket.interactor';
import { httpInterceptorProviders } from './interceptors';
import { AuthService } from './services/auth.service';
import { DateParserFormatterService } from './services/date-parser-formatter.service';
import { DatepickerI18nService } from './services/datepicker-i18n.service';
import { DeviceService } from './services/device.service';
import { ManualService } from './services/manual.service';
import { MgmtDeptService } from './services/mgmt-dept.service';
import { OrganizationService } from './services/organization.service';
import { ReportService } from './services/report.service';
import { StepService } from './services/step.service';
import { ThinkletService } from './services/thinklet.service';
import { UserService } from './services/user.service';
import { WorkService } from './services/work.service';
import { AuthGateway } from './usecases/auth.gateway';
import { AuthUsecase } from './usecases/auth.usecase';
import { DeviceGateway } from './usecases/device.gateway';
import { DeviceUsecase } from './usecases/device.usecase';
import { ManualGateway } from './usecases/manual.gateway';
import { ManualUsecase } from './usecases/manual.usecase';
import { MgmtDeptGateway } from './usecases/mgmt-dept.gateway';
import { MgmtDeptUsecase } from './usecases/mgmt-dept.usecase';
import { OrganizationGateway } from './usecases/organization.gateway';
import { OrganizationUsecase } from './usecases/organization.usecase';
import { ProgressUsecase } from './usecases/progress.usecase';
import { ReportGateway } from './usecases/report.gateway';
import { ReportUsecase } from './usecases/report.usecase';
import { StepGateway } from './usecases/step.gateway';
import { StepUsecase } from './usecases/step.usecase';
import { ThinkletGateway } from './usecases/thinklet.gateway';
import { ThinkletUsecase } from './usecases/thinklet.usecase';
import { ToastUsecase } from './usecases/toast.usecase';
import { UserGateway } from './usecases/user.gateway';
import { UserUsecase } from './usecases/user.usecase';
import { WebSocketUsecase } from './usecases/web-socket.usecase';
import { WorkGateway } from './usecases/work.gateway';

@NgModule({
  providers: [
    httpInterceptorProviders,
    { provide: NgbDateParserFormatter, useClass: DateParserFormatterService },
    { provide: NgbDatepickerI18n, useClass: DatepickerI18nService },
    { provide: AuthUsecase, useClass: AuthInteractor },
    { provide: DeviceUsecase, useClass: DeviceInteractor },
    { provide: ManualUsecase, useClass: ManualInteractor },
    { provide: MgmtDeptUsecase, useClass: MgmtDeptInteractor },
    { provide: OrganizationUsecase, useClass: OrganizationInteractor },
    { provide: ProgressUsecase, useClass: ProgressInteractor },
    { provide: ReportUsecase, useClass: ReportInteractor },
    { provide: StepUsecase, useClass: StepInteractor },
    { provide: ThinkletUsecase, useClass: ThinkletInteractor },
    { provide: ToastUsecase, useClass: ToastInteractor },
    { provide: UserUsecase, useClass: UserInteractor },
    { provide: WebSocketUsecase, useClass: WebSocketInteractor },
    { provide: AuthGateway, useClass: AuthService },
    { provide: DeviceGateway, useClass: DeviceService },
    { provide: ManualGateway, useClass: ManualService },
    { provide: MgmtDeptGateway, useClass: MgmtDeptService },
    { provide: OrganizationGateway, useClass: OrganizationService },
    { provide: ReportGateway, useClass: ReportService },
    { provide: StepGateway, useClass: StepService },
    { provide: ThinkletGateway, useClass: ThinkletService },
    { provide: UserGateway, useClass: UserService },
    { provide: WorkGateway, useClass: WorkService },
  ],
  imports: [CommonModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule Only.');
    }
  }
}
