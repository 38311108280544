import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

const toDateStruct = (date: dayjs.Dayjs): NgbDateStruct => ({ year: date.year(), month: date.month() + 1, day: date.date() });
const toDayjs = (date: NgbDateStruct): dayjs.Dayjs =>
  dayjs()
    .tz()
    .set({ year: date.year, month: date.month - 1, date: date.day });

@Injectable()
export class DateParserFormatterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    return toDateStruct(dayjs.tz(value, 'L'));
  }

  format(date: NgbDateStruct): string {
    return toDayjs(date).format('L');
  }
}
