import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MgmtDept } from '../models/mgmt-dept.model';
import { MgmtDeptGateway } from '../usecases/mgmt-dept.gateway';

@Injectable()
export class MgmtDeptService extends MgmtDeptGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  getMgmtDept(mgmtDeptId: string): Observable<MgmtDept> {
    return this._http.get<MgmtDept>(`api/vgs/mgmt-depts/${mgmtDeptId}`);
  }
}
