import { UAParser } from 'ua-parser-js';

import { ImmutableMap, QueryParams } from './utility.model';

export const DETECTABLE_DEVICE_TYPES = ['pc', 'handset'] as const;
export const ASSIGNABLE_DEVICE_TYPES = ['thinklet'] as const;
export const DEVICE_TYPES = [...DETECTABLE_DEVICE_TYPES, ...ASSIGNABLE_DEVICE_TYPES] as const;
export type DeviceType = (typeof DEVICE_TYPES)[number];

// TODO: UserAgent廃止.
const ua = UAParser(navigator.userAgent);
export const UA_DEVICE_TYPE: DeviceType = ua.device.type === 'mobile' ? 'handset' : 'pc';
export const isIOS: boolean = /iPhone|iPad|iPod/i.test(ua.device.model || '');

export type DeviceQueryParams = QueryParams;

export type Device = {
  name: string;
  owner: string;
  deviceId: string;
  type: DeviceType;
  connectionId: string;
  organizationId: string;
  updatedAt: number;
  version: number;
};

export class Devices extends ImmutableMap<string, Device> {
  constructor(devices?: Device[] | Iterable<Device>) {
    super((device: Device) => device.deviceId, devices);
  }

  protected clone(devices?: Device[] | Iterable<Device>): this {
    return new Devices(devices) as this;
  }
}
