import { Observable } from 'rxjs';

import {
  Thinklet,
  ThinkletCreateParams,
  ThinkletQueryParams,
  ThinkletReactivateParams,
  ThinkletUpdateParams,
} from '../models/thinklet.model';
import { ListResponse } from '../models/utility.model';

export abstract class ThinkletGateway {
  abstract listThinklets(params: ThinkletQueryParams): Observable<ListResponse<Thinklet>>;
  abstract createThinklets(params: ThinkletCreateParams): Observable<ListResponse<Thinklet> & { registerId: string }>;
  abstract updateThinklet(hash: string, params: ThinkletUpdateParams): Observable<Thinklet>;
  abstract deleteThinklet(hash: string): Observable<void>;
  abstract deactivateThinklet(hash: string): Observable<Thinklet>;
  abstract reactivateThinklet(hash: string, params: ThinkletReactivateParams): Observable<Thinklet>;
}
