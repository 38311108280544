import { Observable } from 'rxjs';

import { Work, WorkCreateParams, WorkUpdateParams } from '../models/work.model';

export abstract class WorkGateway {
  abstract createWork(reportId: string, params: WorkCreateParams): Observable<Work>;
  abstract updateWork(reportId: string, workId: string, params: WorkUpdateParams): Observable<Work>;
  abstract updateWorkAssign(reportId: string, workId: string): Observable<Work>;
  abstract deleteWork(reportId: string, workId: string): Observable<void>;
}
