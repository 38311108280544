import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { Progress } from '../models/progress.model';
import { ProgressUsecase } from '../usecases/progress.usecase';

const DISMISS_DELAY = 200;

@Injectable()
export class ProgressInteractor extends ProgressUsecase {
  get progress$(): Observable<Progress | null> {
    return this._progress.pipe(
      switchMap(progress => {
        if (!progress.size) {
          return of(null).pipe(delay(DISMISS_DELAY));
        }
        return of(
          [...progress.values()].reduce(
            (acc, cur) => {
              acc.total += cur.total;
              acc.loaded += cur.loaded;
              return acc;
            },
            { total: 0, loaded: 0 },
          ),
        );
      }),
      distinctUntilChanged(),
    );
  }

  private readonly _progress = new BehaviorSubject<Map<string, Progress>>(new Map());

  show(): string {
    const id = uuid();
    const map = this._progress.value;
    this._progress.next(map.set(id, { total: 0, loaded: 0 }));
    return id;
  }

  update(id: string, progress: Progress): void {
    const map = this._progress.value;
    if (map.has(id)) {
      this._progress.next(map.set(id, progress));
    }
  }

  dismiss(id: string): void {
    const map = this._progress.value;
    if (map.delete(id)) {
      this._progress.next(map);
    }
  }
}
