import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Device, DeviceQueryParams } from '../models/device.model';
import { ListResponse } from '../models/utility.model';
import { DeviceGateway } from '../usecases/device.gateway';

@Injectable()
export class DeviceService extends DeviceGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listDevices(params: DeviceQueryParams): Observable<ListResponse<Device>> {
    return this._http.get<ListResponse<Device>>('api/vgs/devices', { params });
  }
}
