import { Observable } from 'rxjs';

import { Report, ReportCreateParams, ReportQueryParams, ReportUpdateParams } from '../models/report.model';
import { ListResponse } from '../models/utility.model';

export abstract class ReportGateway {
  abstract listReports(params: ReportQueryParams): Observable<ListResponse<Report>>;
  abstract createReport(params: ReportCreateParams): Observable<Report>;
  abstract updateReport(reportId: string, params: ReportUpdateParams): Observable<Report>;
  abstract deleteReport(reportId: string): Observable<void>;
}
