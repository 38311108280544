import { Prop, RequiredOnly, isNumber, isObject, isString } from './utility.model';

export type MgmtDept = {
  mgmtDeptId: string;
  name: string;
  policyUpdatedAt: number;
  updatedAt: number;
  version: number;
};

export function isMgmtDept(value: unknown): value is MgmtDept {
  const sProps: Prop<RequiredOnly<MgmtDept>, string>[] = ['mgmtDeptId', 'name'];
  const nProps: Prop<RequiredOnly<MgmtDept>, number>[] = ['policyUpdatedAt', 'updatedAt', 'version'];
  return isObject(value) && sProps.every(prop => isString(value[prop])) && nProps.every(prop => isNumber(value[prop]));
}
