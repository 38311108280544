import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Work, WorkCreateParams, WorkUpdateParams } from '../models/work.model';
import { WorkGateway } from '../usecases/work.gateway';

@Injectable()
export class WorkService extends WorkGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  createWork(reportId: string, params: WorkCreateParams): Observable<Work> {
    return this._http.post<Work>(`api/vgs/reports/${reportId}/works`, params);
  }

  updateWork(reportId: string, workId: string, params: WorkUpdateParams): Observable<Work> {
    return this._http.put<Work>(`api/vgs/reports/${reportId}/works/${workId}`, params);
  }

  updateWorkAssign(reportId: string, workId: string): Observable<Work> {
    return this._http.put<Work>(`api/vgs/reports/${reportId}/works/${workId}/assign`, null);
  }

  deleteWork(reportId: string, workId: string): Observable<void> {
    return this._http.delete<void>(`api/vgs/reports/${reportId}/works/${workId}`);
  }
}
