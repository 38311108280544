import { Observable } from 'rxjs';

import { UserCreateParams, UserUpdateParams, Users } from '../models/user.model';

export abstract class UserUsecase {
  abstract get users$(): Observable<Users>;

  abstract createUser(params: UserCreateParams): Observable<never>;
  abstract updateUser(userId: string, params: UserUpdateParams): Observable<never>;
  abstract deleteUser(userId: string): Observable<never>;
  abstract resendUserInvite(userId: string): Observable<never>;
  abstract deleteUserTrackingDevices(userId: string): Observable<never>;
  abstract updateUserRestriction(userId: string, enabled: boolean): Observable<never>;
  abstract updateUserConsent(userId: string, consentedAt: number): Observable<never>;
}
