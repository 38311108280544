import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Manual,
  ManualCreateParams,
  ManualQueryParams,
  ManualStep,
  ManualStepQueryParams,
  ManualStepsCreateParams,
} from '../models/manual.model';
import { ListResponse } from '../models/utility.model';
import { ManualGateway } from '../usecases/manual.gateway';

@Injectable()
export class ManualService extends ManualGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listManuals(params: ManualQueryParams): Observable<ListResponse<Manual>> {
    return this._http.get<ListResponse<Manual>>('api/vgs/manuals', { params });
  }

  createManual(params: ManualCreateParams): Observable<Manual> {
    return this._http.post<Manual>(`api/vgs/manuals`, params);
  }

  deleteManual(manualId: string): Observable<void> {
    return this._http.delete<void>(`api/vgs/manuals/${manualId}`);
  }

  listManualStep(manualId: string, params: ManualStepQueryParams): Observable<ListResponse<ManualStep>> {
    return this._http.get<ListResponse<ManualStep>>(`api/vgs/manuals/${manualId}/steps`, { params });
  }

  createManualSteps(manualId: string, params: ManualStepsCreateParams): Observable<ListResponse<ManualStep>> {
    return this._http.post<ListResponse<ManualStep>>(`api/vgs/manuals/${manualId}/steps`, params);
  }
}
