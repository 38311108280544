import { Observable } from 'rxjs';

import { TrackingDevice, TrackingDeviceQueryParams } from '../models/tracking-device.model';
import { User, UserCreateParams, UserQueryParams, UserUpdateParams } from '../models/user.model';
import { ListResponse } from '../models/utility.model';

export abstract class UserGateway {
  abstract listUsers(params: UserQueryParams): Observable<ListResponse<User>>;
  abstract createUser(params: UserCreateParams): Observable<User>;
  abstract updateUser(userId: string, params: UserUpdateParams): Observable<User>;
  abstract deleteUser(userId: string): Observable<void>;
  abstract resendUserInvite(userId: string): Observable<void>;
  abstract listUserTrackingDevices(userId: string, params: TrackingDeviceQueryParams): Observable<ListResponse<TrackingDevice>>;
  abstract deleteUserTrackingDevices(userId: string): Observable<void>;
  abstract updateUserRestriction(userId: string, restriction: { enabled: boolean }): Observable<User>;
  abstract updateUserConsent(userId: string, consent: { consentedAt: number }): Observable<User>;
}
