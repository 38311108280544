import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NeverError } from '../models/error.model';
import { Thinklet, ThinkletCreateParams, ThinkletReactivateParams, ThinkletUpdateParams, Thinklets } from '../models/thinklet.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { ThinkletGateway } from '../usecases/thinklet.gateway';
import { ThinkletUsecase } from '../usecases/thinklet.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class ThinkletInteractor extends ThinkletUsecase {
  get thinklets$(): Observable<Thinklets> {
    return this._thinklets;
  }

  private readonly _thinklets = new DistinctSubject<Thinklets>(new Thinklets());

  constructor(private _webSocketUsecase: WebSocketUsecase, private _thinkletGateway: ThinkletGateway) {
    super();

    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'thinklet'),
        map(({ data }) => data as WebSocketSyncData<Thinklet>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update': {
            this._thinklets.next(this._thinklets.value.set(data.payload));
            break;
          }
          case 'delete':
            this._thinklets.next(this._thinklets.value.delete(data.payload.imei));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createThinklets(params: ThinkletCreateParams): Observable<string> {
    const result = new AsyncSubject<string>();
    this._thinkletGateway.createThinklets(params).subscribe({
      next: ({ items, registerId }) => {
        const thinklets = items.reduce((acc, cur) => acc.set(cur), this._thinklets.value);
        this._thinklets.next(thinklets);
        result.next(registerId);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateThinklet(hash: string, params: ThinkletUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.updateThinklet(hash, params).subscribe({
      next: thinklet => this._thinklets.next(this._thinklets.value.set(thinklet)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteThinklet(hash: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.deleteThinklet(hash).subscribe({
      next: () => this._thinklets.next(this._thinklets.value.delete(hash)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deactivateThinklet(hash: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.deactivateThinklet(hash).subscribe({
      next: thinklet => this._thinklets.next(this._thinklets.value.set(thinklet)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reactivateThinklet(hash: string, params: ThinkletReactivateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._thinkletGateway.reactivateThinklet(hash, params).subscribe({
      next: thinklet => this._thinklets.next(this._thinklets.value.set(thinklet)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._thinkletGateway.listThinklets(params), {}).subscribe(thinklets => {
      this._thinklets.next(new Thinklets(thinklets));
    });
  }

  private onSignOut(): void {
    this._thinklets.next(new Thinklets());
  }
}
