import { Observable } from 'rxjs';

import {
  ListVideoQueryParams,
  ResultReportCreateParams,
  StepQueryParams,
  StepUpdateParams,
  Steps,
  VoiceQueryParams,
} from '../models/step.model';

export abstract class StepUsecase {
  abstract get steps$(): Observable<Steps>;

  abstract listSteps(params: StepQueryParams): Observable<never>;
  abstract updateStep(reportId: string, workId: string, step: number, params: StepUpdateParams): Observable<void>;
  abstract listVideos(params: ListVideoQueryParams): Observable<string[]>;
  abstract getVoice(params: VoiceQueryParams): Observable<string>;

  abstract clearSteps(): void;
  abstract exportReport(params: ResultReportCreateParams): Observable<never>;
}
